import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';
import type { Request, Response } from 'o365.pwa.declaration.sw.ServiceWorkerGlobalScope.d.ts';
import type { StrategyOptions, Strategy, StrategyHandler } from 'o365.pwa.declaration.sw.workbox.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

declare module 'o365.pwa.declaration.sw.strategies.DynamicWorkboxStrategy.d.ts' {
    export type WorkboxStrategy = 'CacheFirst' | 'CacheOnly' | 'NetworkFirst' | 'NetworkFirstOptions' | 'NetworkOnly' | 'NetworkOnlyOptions' | 'StaleWhileRevalidate' | 'O365-Offline-Sync' | 'O365-Cache-And-Network-Race';

    export interface IDynamicWorkboxStrategy extends StrategyOptions {
        networkTimeoutSeconds?: number;
    }

    export class DynamicWorkboxStrategy extends Strategy {
        constructor(options?: IDynamicWorkboxStrategy);

        _handle(request: Request, handler: StrategyHandler): Promise<Response | undefined>;
    }
}
